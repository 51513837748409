export namespace AppStyles {
    export class Links {
        public static readonly HeaderLinkStyle = {
            color: 'white',
            'text-decoration': 'none',
            'right-margin': '10px',
        };
        public static readonly FooterLinkStyle = {
            color: 'white',
            'text-decoration': 'none',
       };
   };
}