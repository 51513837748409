export function AppName() {
    return (<>Tools That See</>);
}
export function AppWebsite() {
    return (<>www.toolsthatsee.com</>);
}
export function MailToPrivacyLink() {
    return (<a href="mailto:privacy@onpoint.com">privacy@onpoint.com</a>);
}
export function MailToInfoLink() {
    return (<a href="mailto:privacy@onpoint.com">info@onpoint.com</a>);
}
export const reCaptchaSiteKey: string = "6Lcdc4cjAAAAAK1XpRj46SR-KN64CgxrtCt9YqwW"
export const reCaptchaSecretKey: string = "6Lcdc4cjAAAAAD3Oncm_cOy9j2MFD61Y0kzLSdXf"
