import GetCookie, { Cookies, DeleteCookie, SetCookie } from "../../functions/Cookies";

export interface PrivacyCookieProps {
    cookiePreferencesSet: boolean,
    functionalCookies: boolean,
    performanceCookies: boolean,
    targetingCookies: boolean,
}

export function GetPrivacyConsentCookie(): PrivacyCookieProps | null {
    var privacyConsentCookieJson: string | null = GetCookie(Cookies.Names.PrivacyConsentCookieName);
    var privacyConsentCookie: PrivacyCookieProps | null = null;

    if (privacyConsentCookieJson !== null) {
        try {
            privacyConsentCookie = JSON.parse(privacyConsentCookieJson);
        }
        catch {
            privacyConsentCookie = null;
        }
    }

    return privacyConsentCookie;
}
export function SetPrivacyConsentCookie(privacyCookieProps: PrivacyCookieProps): void {
    if (privacyCookieProps.cookiePreferencesSet || privacyCookieProps.functionalCookies || privacyCookieProps.performanceCookies || privacyCookieProps.targetingCookies) {
        var privacyConsentCookieJson = JSON.stringify(privacyCookieProps);
        SetCookie(Cookies.Names.PrivacyConsentCookieName, privacyConsentCookieJson);
    }
    else {
        DeleteCookie(Cookies.Names.PrivacyConsentCookieName);
    }
    window.location.reload();
    return;
}
export function DeletePrivacyConsentCookie(): void {
    DeleteCookie(Cookies.Names.PrivacyConsentCookieName);
    window.location.reload();
    return;
}
