import React from 'react';
import '../css/app.css';
import './PrivacyPage.css';

export default function PrivacyLinksSection() {
    return (
        <div>
            <h2>Links</h2>
            <p>OnPoint contains links to other websites, including links that are placed there by OnPoint.
                Please be aware that OnPoint is not responsible for the privacy policies or practices on other websites.
                We encourage you to be aware of when you leave our Website, and to read the privacy policies of each and every website that collects your personal information.
                Our Privacy Policy only governs information collected on the Website.</p>
        </div>
    );
}
