import React from 'react';
import './css/app.css';

export default function AppCopyright() {
    return (
        <div className="app-copyright">
            Copyright &copy; 2022 <a href="https://www.onpoint.com" target="_blank" rel="noreferrer">OnPoint Technologies, LLC</a>. All Rights Reserved.
        </div>
    );
}
