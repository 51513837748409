import { Toggle } from '@fluentui/react';
import React from 'react';
import '../../css/app.css';

export default function NecessaryCookies() {
    return (
        <div className="privacy-settings-content">
            <div className="private-settings-tab-content-header">
                <div>
                    <Toggle className="privacy-read-only-toogle" label="" checked={true} />
                </div>
                <div>
                    <h2>Necessary Cookies (always active)</h2>
               </div>
            </div>
            <p>These cookies are necessary for the website to function and cannot be switched off in our systems.
                They are only set in response to actions made by you as a request for services, such as setting your privacy preferences, logging in or filling in forms.
                You can set your browser to block or alert you about these cookies, but some parts of the site will not then work.</p>
                <p><b>These cookies do not store any personally identifiable information.</b></p>
        </div>
    );
}
