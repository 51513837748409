import React from 'react';
import { useRouteError } from 'react-router-dom';
import '../css/app.css';
import './ErrorPage.css';

export default function ErrorPage() {
    const error = useRouteError() as any;
    return (
        <div className="error-page">
            <title>OnPoint Website Error</title>
            <p>
                <i>{error.statusText || error.message}</i>
            </p>
        </div>
    );
}
