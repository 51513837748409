import React, { useState } from 'react';
import { ContextualMenu, DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import '../../css/app.css';
import './PrivacySettings.css';
import { useBoolean } from '@fluentui/react-hooks';
import { DeletePrivacyConsentCookie, GetPrivacyConsentCookie, PrivacyCookieProps, SetPrivacyConsentCookie } from './PrivacyCookies';
import YourPrivacy from './YourPrivacy';
import FunctionalCookies from './FunctionalCookies';
import NecessaryCookies from './NecessaryCookies';
import PerformanceCookies from './PerformanceCookies';
import TargetingCookies from './TargetingCookies';
import { TabControl, TabsControl } from '../../components/TabControls';

const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
};
const modalPropsStyles = { main: { maxWidth: 450 } };
const dialogContentProps = {
    type: DialogType.normal,
    title: 'Privacy Settings Center',
    //    subText: 'Do you want to send this message without a subject?',
};
export default function PrivacySettings() {
    const privacyConsentCookie: PrivacyCookieProps | null = GetPrivacyConsentCookie();
    const isDraggable = false;
    const [activeTabId, setActiveTabId] = useState('privacy');
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [allowFunctionalCookies, setAllowFunctionalCookies] = useState((privacyConsentCookie) ? privacyConsentCookie?.functionalCookies : false);
    const [allowPerformanceCookies, setAllowPerformanceCookies] = useState((privacyConsentCookie) ? privacyConsentCookie?.performanceCookies : false);
    const [allowTargetingCookies, setAllowTargetingCookies] = useState((privacyConsentCookie) ? privacyConsentCookie?.targetingCookies : false);
    //const styles = useStyles(); 

    const modalProps = React.useMemo(
        () => ({
            title: 'Privacy Settings',
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: isDraggable ? dragOptions : undefined,
        }),
        [isDraggable],
    );
    const handlePrivacySettingsClick = (e: { preventDefault: () => void; }) => {
        toggleHideDialog();
    }
    const handleAllowAllClicked = (e: { preventDefault: () => void; }) => {
        setAllowFunctionalCookies(true);
        setAllowPerformanceCookies(true);
        setAllowTargetingCookies(true);
    }
    const handleRejectAllClicked = (e: { preventDefault: () => void; }) => {
        setAllowFunctionalCookies(false);
        setAllowPerformanceCookies(false);
        setAllowTargetingCookies(false);
    }
    const handleFunctionalSettingToggled = (e: { preventDefault: () => void; }, checked?: boolean | undefined) => {
        setAllowFunctionalCookies(returnBoolean(checked));
    }
    const handlePerformanceSettingToggled = (e: { preventDefault: () => void; }, checked?: boolean | undefined) => {
        setAllowPerformanceCookies(returnBoolean(checked));
    }
    const handleTargetingSettingToggled = (e: { preventDefault: () => void; }, checked?: boolean | undefined) => {
        setAllowTargetingCookies(returnBoolean(checked));
    }
    const returnBoolean = (checked?: boolean | undefined): boolean => {
        if (checked === undefined) {
            return false;
        }
        return checked;
    }
    const handleSaveClicked = (e: { preventDefault: () => void; }) => {
        SetPrivacyConsentCookie({ cookiePreferencesSet: true, functionalCookies: allowFunctionalCookies, performanceCookies: allowPerformanceCookies, targetingCookies: allowTargetingCookies });
        toggleHideDialog();
    }
    const handleResetClicked = (e: { preventDefault: () => void; }) => {
        DeletePrivacyConsentCookie();
    }
    const onTabChanged = (activeTabId: string) => {
        setActiveTabId(activeTabId);
    }
    return (
        <>
            <button type="button" onClick={handlePrivacySettingsClick}>Privacy Settings</button>
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
                maxWidth={800}
            >
                <div className="privacy-settings-tabs">
                    <TabsControl defaultSelectedTabId="privacy" onTabChanged={onTabChanged} appearance="subtle" vertical>
                        <TabControl tabId="privacy" label="Your Privacy" />
                        <TabControl tabId="functional" label="Functional Cookies" />
                        <TabControl tabId="necessary" label="Necessary Cookies" />
                        <TabControl tabId="performance" label="Performance Cookies" />
                        <TabControl tabId="targeting" label="Targeting Cookies" />
                    </TabsControl>
                    <div className="private-settings-tab-content">
                        {activeTabId === 'privacy' && <YourPrivacy />}
                        {activeTabId === 'functional' && <FunctionalCookies onToggled={handleFunctionalSettingToggled} checked={allowFunctionalCookies} />}
                        {activeTabId === 'necessary' && <NecessaryCookies />}
                        {activeTabId === 'performance' && < PerformanceCookies onToggled={handlePerformanceSettingToggled} checked={allowPerformanceCookies} />}
                        {activeTabId === 'targeting' && <TargetingCookies onToggled={handleTargetingSettingToggled} checked={allowTargetingCookies} />}
                    </div>
                </div>
                {/*<Toggle label="Allow All Cookies" onChange={handleAllowAllCookiedToogled} checked={allowAllCookies} />*/}
                {/*<Toggle label="Reject All Cookies" onChange={handleRejectAllCookiedToogled} checked={rejectAllCookies} />*/}
                <DialogFooter>
                    <PrimaryButton onClick={handleSaveClicked} text="Confirm My Choices" />
                    <DefaultButton onClick={handleRejectAllClicked} text="Reject All" />
                    <DefaultButton onClick={handleAllowAllClicked} text="Allow All" />
                    <DefaultButton onClick={handleResetClicked} text="Reset" />
                    <DefaultButton onClick={toggleHideDialog} text="Cancel" />
                </DialogFooter>
            </Dialog>
        </>
    );
}
