import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import ContactPage from "./pages/ContactPage";
import ErrorPage from "./pages/ErrorPage";
import HomePage from "./pages/HomePage";
import PrivacyPage from "./privacy/PrivacyPage";
import TermsOfUsePage from "./termsofuse/TermsOfUsePage";

export default function createAppRouter() {
    const router = createBrowserRouter(
        [
            {
                path: "/", element: <App />, errorElement: <ErrorPage />, children:
                    [
                        { path: "", element: <HomePage /> },
                        { path: "home", element: <HomePage /> },
                        { path: "contact", element: <ContactPage /> },
                        { path: "privacy", element: <PrivacyPage /> },
                        { path: "termsofuse", element: <TermsOfUsePage /> },
                    ]
            },
        ]
    );
    return router;
}
