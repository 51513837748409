import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import { Outlet } from 'react-router-dom';
import './css/app.css';
import AppFooter from './AppFooter';
import AppHeader from './AppHeader';
import CookieConsent, { HasCookieConsent } from './privacy/cookies/CookieConsent';

export default function App() {
    return (
        <div className="App">
            <AppHeader />
            <div className="App-body-viewport">
                <div className="App-body">
                    <Outlet />
                    {HasCookieConsent()
                        ? null
                        : <CookieConsent />
                    }
                </div>
            </div>
            <AppFooter />
        </div>
    );
}
