import React from 'react';
import '../css/app.css';
import './HomePage.css';

export default function HomePage() {
    return (
        <div className="home-page">
            home page
      </div>
    );
}
