import React from 'react';
import '../css/app.css';
import './PrivacyPage.css';

export default function PrivacySecuritySection() {
    return (
        <div>
            <h2>Security</h2>
            <p>OnPoint implements commercially reasonable security measures designed to protect your Personally Identifiable Information.</p>
        </div>
    );
}
