import React from 'react';
import { Link } from "react-router-dom";
import logo from './images/OnPointLogoMidBlue32.png';
import './css/app.css';
import { AppStyles } from './AppStyles';

export default function AppHeader() {
    return (
        <div className="App-header">
            <div className="App-header-logo-div"><img src={logo} className="App-banner-logo" alt="logo" /></div>
            <div className="App-header-div">
                <Link to="/" style={AppStyles.Links.HeaderLinkStyle} >
                    Home
                </Link>
            </div>
        </div>
    );
}
