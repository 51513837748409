import React from 'react';
import '../css/app.css';
import './TemsOfUsePage.css';

export default function TemsOfUsePage() {
    return (
        <div className="terms-of-use-page">
            <div className="terms-of-use-header">
                <h1 className="terms-of-use-h1">Terms of Use</h1>
            </div>
            <div className="terms-of-use-body">
                <div>
                    Last updated: November 3, 2022.<br />
                </div>
                <div>
                    <h2>Acceptance of Terms</h2>
                    <p>The services that OnPoint provides to you are subject to the following Terms of Use (TOU).
                        OnPoint reserves the right to update and modify the TOU at any time without notice to you.
                        The most current version of the TOU can be reviewed by clicking on the "Terms of Use" hyperlink located at the bottom of our webpages.
                        When we make updates to the TOU, OnPoint will update the date at the top of this page.
                        By using the website after a new version of the TOU has been posted, you agree to the terms of such new version.</p>
                </div>
                <div>
                    <h2>Description of Services</h2>
                    <p>Through its network of Web properties and Apps, OnPoint provides you with access to a variety of resources, including DIY tools, download areas, communication forums and product information (collectively "Services").
                        The Services, including any updates, enhancements, new features, and/or the addition of any new Web properties or Apps, are subject to these TOU.</p>
                </div>
                <div>
                    <h2>Personal and Non-Commercial Use Limitation</h2>
                    <p>Unless otherwise specified, the Services are for your personal and non-commercial use.
                        You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, software, products, or services obtained from the Services.</p>
                </div>
                <div>
                    <h2>Privacy and Protection of Personal Information</h2>
                    <p>Review the <a href="/privacy">Privacy Statement</a> disclosures relating to the collection and use of your personal data.</p>
                </div>
                <div>
                    <h2>Content</h2>
                    <p>All content included in or made available through the Services, such as text, graphics, logos, icons, images, sounds, music, digital downloads, data compilation, software, and documents, is the exclusive property of OnPoint or its content suppliers and is protected by the various applicable trade dress, copyright, trademark, patent, and other intellectual property and unfair competition laws in the United States and internationally (collectively, "Content").
                        All rights not expressly granted to you in this TOU are reserved and retained by OnPoint or its licensors, suppliers, publishers, rightsholders, or other content providers.</p>
                </div>
                <div>
                    <h2>Software</h2>
                    <p>Any software that is made available to download from the Services ("Software") is the copyrighted work of OnPoint and/or its suppliers.
                        Use of the Software is governed by the terms of the end user license agreement, if any, which accompanies or is included with the Software ("License Agreement").
                        An end user will be unable to install any Software that is accompanied by or includes a License Agreement, unless he or she first agrees to the License Agreement terms.
                        Third party scripts or code, linked to or referenced from this website, are licensed to you by the third parties that own such code, not by OnPoint.</p>

                    <p>The Software is made available for download solely for use by end users according to the License Agreement.
                        <b> Any reproduction or redistribution of the Software not in accordance with the License Agreement is expressly prohibited by law and may result in severe civil and criminal penalties. </b>
                        Violators will be prosecuted to the maximum extent possible.</p>

                    <p>Without limiting the foregoing, copying or reproduction of the software to any other server or location for further reproduction or redistribution is expressly prohibited, unless such reproduction or redistribution is expressly permitted by the license agreement accompanying such software.</p>

                    <p>The software is warranted, if at all, only according to the terms of the license agreement. <b>Except as warranted in the license agreement, OnPoint Technologies, LLC hereby disclaims all warranties and conditions with regard to the software, including all warranties and conditions of merchantability, whether express, implied or statutory, fitness for a particular purpose, title and non-infringement.</b>
                        For your convenience, OnPoint may make available as part of the services or in its software products, tools, and utilities for use and/or download. <b>OnPoint does not make any assurances with regard to the accuracy of the results or output that derives from such use of any such software products, tools and utilities.</b></p>
                </div>
                {/*<div>*/}
                {/*    <h2>Documents</h2>*/}
                {/*    <p></p>*/}
                {/*</div>*/}
                <div>
                    <h2>Limitation of Liability</h2>
                    <p>In no event shall  OnPoint and/or its respective suppliers be liable for any special, indirect or consequential damages, or any damages whatsoever resulting from loss of use, data, or profits, whether in an action of contract, negligence or other tortious action, arising out of or in connection with the use or performance of software, documents, provision of or failure to provide services, or information available from the services.</p>
                </div>
                <div>
                    <h2>Member Account, Password, and Security</h2>
                    <p>If any of the Services requires you to open an account, you must complete the registration process by providing us with current, complete, and accurate information as prompted by the applicable registration form.
                        You also will choose a password and a username. You are entirely responsible for maintaining the confidentiality of your password and account.
                        Furthermore, you are entirely responsible for any and all activities that occur under your account.
                        You agree to notify  OnPoint immediately of any unauthorized use of your account or any other breach of security.
                        OnPoint will not be liable for any loss that you may incur as a result of someone else using your password or account, either with or without your knowledge.
                        However, you could be held liable for losses incurred by OnPoint or another party due to someone else using your account or password.
                        You may not use anyone else's account at any time, without the permission of the account holder.</p>
                </div>
                <div>
                    <h2>No Unlawful or Prohibited Use</h2>
                    <p>As a condition of your use of the Services, you will not use the Services for any purpose that is unlawful or prohibited by these terms, conditions, and notices.
                        You may not use the Services in any manner that could damage, disable, overburden, or impair any OnPoint server, or the network(s) connected to any OnPoint server, or interfere with any other party's use and enjoyment of any Services.
                        You may not attempt to gain unauthorized access to any Services, other accounts, computer systems or networks connected to any  OnPoint server or to any of the Services, through hacking, password mining or any other means.
                        You may not obtain or attempt to obtain any materials or information through any means not intentionally made available through the Services.
                        You may not attempt to copy or change, alter, or otherwise attempt to modify the Services or these TOU.</p>
                </div>
                {/*<div>*/}
                {/*    <h2>Use of Services</h2>*/}
                {/*    <p></p>*/}
                {/*</div>*/}
                <div>
                    <h2>Materials Provided to OnPoint or Posted at any OnPoint Website or App</h2>
                    <p> OnPoint does not claim ownership of the materials you provide to OnPoint (including feedback and suggestions) or post, upload, input or submit to any Services or its associated services for review by the general public, or by the members of any public or private community, (each a "Submission" and collectively "Submissions"). However, by posting, uploading, inputting, providing or submitting ("Posting") your Submission you are granting OnPoint, its affiliated companies and necessary sublicensees permission to use your Submission in connection with the operation of their Internet businesses (including, without limitation, all  OnPoint Services), including, without limitation, the license rights to: copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and reformat your Submission; to publish your name in connection with your Submission; and the right to sublicense such rights to any supplier of the Services.</p>

                    <p>No compensation will be paid with respect to the use of your Submission, as provided herein.
                        OnPoint is under no obligation to post or use any Submission you may provide, and OnPoint may remove any Submission at any time in its sole discretion.</p>

                    <p>By Posting a Submission you warrant and represent that you own or otherwise control all of the rights to your Submission as described in these Terms of Use including, without limitation, all the rights necessary for you to provide, post, upload, input or submit the Submissions.</p>

                    <p>In addition to the warranty and representation set forth above, by Posting a Submission that contain images, photographs, pictures or that are otherwise graphical in whole or in part("Images"), you warrant and represent that(a) you are the copyright owner of such Images, or that the copyright owner of such Images has granted you permission to use such Images or any content and / or images contained in such Images consistent with the manner and purpose of your use and as otherwise permitted by these Terms of Use and the Services, (b) you have the rights necessary to grant the licenses and sublicenses described in these Terms of Use, and (c) that each person depicted in such Images, if any, has provided consent to the use of the Images as set forth in these Terms of Use, including, by way of example, and not as a limitation, the distribution, public display and reproduction of such Images.
                        By Posting Images, you are granting(a) to all members of your private community(for each such Images available to members of such private community), and / or (b) to the general public (for each such Images available anywhere on the Services, other than a private community), permission to use your Images in connection with the use, as permitted by these Terms of Use, of any of the Services, (including, by way of example, and not as a limitation, making prints and gift items which include such Images), and including, without limitation, a non-exclusive, world-wide, royalty-free license to: copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and reformat your Images without having your name attached to such Images, and the right to sublicense such rights to any supplier of the Services.The licenses granted in the preceding sentences for Images will terminate at the time you completely remove such Images from the Services, provided that, such termination shall not affect any licenses granted in connection with such Images prior to the time you completely remove such Images.
                        No compensation will be paid with respect to the use of your Images.</p>
                </div>
                <div>
                    <h2>Notices and Procedure for Making Claims of Copyright Infringement</h2>
                    <p>Pursuant to Title 17, United States Code, Section 512(c)(2), notifications of claimed copyright infringement should be sent to Service Provider's Designated Agent.
                        All inquiries not relevant to the following procedure will not receive a response.</p>
                </div>
                <div>
                    <h2>Links to Third-party Sites</h2>
                    <p>The links in this area will let you leave OnPoint's site.
                        The linked sites are not under the control of OnPoint and OnPoint is not responsible for the contents of any linked site or any link contained in a linked site, or any changes or updates to such sites.
                        OnPoint is not responsible for webcasting, or any other form of transmission received from any linked site.
                        OnPoint is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by OnPoint of the site.</p>
                </div>
                <div>
                    <h2>Unsolicited Idea Submission Policy</h2>
                    <p>OnPoint or any of its employees do not accept or consider unsolicited ideas, including ideas for new advertising campaigns, new promotions, new products or technologies, processes, materials, marketing plans or new product names.
                        Please do not send any original creative artwork, samples, demos, or other works.
                        The sole purpose of this policy is to avoid potential misunderstandings or disputes when OnPoint's products or marketing strategies might seem similar to ideas submitted to OnPoint.
                        So, please do not send your unsolicited ideas to OnPoint or anyone at OnPoint.
                        If, despite our request that you not send us your ideas and materials, you still send them, please understand that OnPoint makes no assurances that your ideas and materials will be treated as confidential or proprietary.</p>
                </div>

                {/*    <ReactMarkdown>{markdown}</ReactMarkdown>*/}
            </div>
        </div >
    );
}
